// Libs
import React from 'react';
import BasicPageComponent from "../../components/basicPage/basicPage";
// Deps

const BasicPage =({ pageContext }) => {
const { campaign } = pageContext;
  return(
    <div>
    <BasicPageComponent data={campaign}/>
    </div>
  )
}

export default BasicPage;
