import React from 'react';
import Layout from "../layout/layout";
import SEO from "../layout/seo";
import StaticContentPage from "../common/static-content/static-content";


const BasicPageComponent = ({ data }) => {
  const {title, field_subtitle, body } = data;
  return(<Layout>
    <SEO title={title} />
    <StaticContentPage  title={title}
                        subtitle={field_subtitle}
                        body={body}
    />
  </Layout>)
}

export default BasicPageComponent;
