import React from 'react';
import { uniqueId, isEmpty } from 'lodash';
import styles from './static-content.module.scss';

import StaticContentList from './static-content-list';

const StaticContentPage = ({ title, subtitle, items, numItemsPerLine, viewAllLink, body }) => {
  const keys = items ? Object.keys(items) : null;

  return (
    <div className={styles.staticContentMain}>
      <div className={styles.staticContentBody}>
        <div className={styles.staticContentTitle}>{title}</div>
        {subtitle && <div
          className={styles.staticContentSubtitle}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />}
        {keys && keys.map((key, index) => (
          <StaticContentList
            listingTitle={items[key][0].headline}
            items={items[key]}
            key={uniqueId('static-list-item')}
            viewAllLink={viewAllLink}
            numItemsPerLine={numItemsPerLine}
          />
        ))}
        {body && <div className={styles.staticContentMainBody} dangerouslySetInnerHTML={{__html: body}}/>}
      </div>
    </div>
  );
};

export default StaticContentPage;
